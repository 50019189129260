import { useState, useEffect, useRef, Fragment } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./cv.scss";
import video from "../video/Modern.mp4";
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {useSearchParams} from "react-router-dom"
import { Icon, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Switch from '@mui/material/Switch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import ComputerIcon from "@mui/icons-material/Computer";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import TranslateIcon from "@mui/icons-material/Translate";
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AnimationIcon from '@mui/icons-material/Animation';
import PlaceIcon from "@mui/icons-material/Place";
import MailIcon from "@mui/icons-material/Mail";
import MaleIcon from "@mui/icons-material/Male";
import FlagIcon from "@mui/icons-material/Flag";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import PersonIcon from "@mui/icons-material/Person";
import IosShareIcon from '@mui/icons-material/IosShare';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/Download';

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
function Contact() {
  const { t, i18n } = useTranslation();

  const openInNewTab = (url:string) => {
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <Fragment>
      <Typography variant='h4' sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" }, textAlign: "center", color: "text.secondary" }}>
      {t("Alexis")}<br></br> {t("Dos Santos")}
      </Typography>
      <Typography variant='h4' sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" }, textAlign: "center", color: "text.secondary" }}>
      {t("Alexis")} {t("Dos Santos")}
      </Typography>
      <List  sx={{ width: "100%", bgcolor: "background.paper", }} >
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <CalendarMonthIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t("Date of birth")} secondary='04/03/1986 ' />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <FlagIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t("Nationality")} secondary={t("Portuguese")} />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <MaleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t("Gender")} secondary={t("Male")} />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <PlaceIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t("Adress")} secondary={t(" Portugal")} />
        </ListItem>
        <Divider variant='inset' component='li' />
        <ListItem
          secondaryAction={
            <IconButton edge='end' aria-label='email link' onClick={()=>{ window.location.href = "mailto:alexis.jorge.dossantos@gmail.com";}}>
              <ArrowOutwardIcon />
            </IconButton>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <MailIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("Email")}
            secondary="alexis.jorge.dossantos@gmail.com"
          />
        </ListItem>
        <Divider variant='inset' component='li' />

        <ListItem
          secondaryAction={
            <IconButton edge='end' onClick={()=>{openInNewTab("https://www.linkedin.com/in/alexis-santos-9127b936/")}} aria-label='LinkedIn Link'>
              <ArrowOutwardIcon />
            </IconButton>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <LinkedInIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="LinkedIn"/>
        </ListItem>
        <Divider variant='inset' component='li' />
      </List>
    </Fragment>
  );
}

export default function Cv() {





  const { t, i18n } = useTranslation();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [tab, setTab] = useState(0);
  const [timer, setTimer] = useState<any>(null);

  const scroller = useRef<HTMLInputElement>(null);
  const section1 = useRef<HTMLInputElement>(null);
  const section2 = useRef<HTMLInputElement>(null);
  const section3 = useRef<HTMLInputElement>(null);
  const section4 = useRef<HTMLInputElement>(null);
  const section5 = useRef<HTMLInputElement>(null);
  const section6 = useRef<HTMLInputElement>(null);
  const section7 = useRef<HTMLInputElement>(null);

  const sections = [
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
    section7,
  ];
  const [turbulence, setTurbulence] = useState("0.00001 0.00001");

  const [movement, setMovement] = useState(true);
  const [transition, setTransition] = useState(true);
  const [videoBackground, setVideoBackground] = useState(true);
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [p0Class, setP0Class] = useState("spe-hidden");
  const [p1Class, setP1Class] = useState("spe-hidden");

  const [p2Class, setP2Class] = useState("");
  
  const [value, setValue] = useState("1");
  const [screen, setScreen] = useState(2);

  const [rotationFactor, setRotationFactor] = useState([0, 0]);

  const [queryParameters, setSearchParams] = useSearchParams()
  const person = queryParameters.get("person") || "";
  const lng = queryParameters.get("lng")?.toLowerCase() || "";

  const isTouchScreenDevice = () => {
    try{
        document.createEvent('TouchEvent');
        return true;
    }catch(e){
        return false;
    }
}
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    document.title = 'CV - Alexis Dos Santos';
    if(lng)
    {
      setLanguage(lng)
      i18n.changeLanguage(lng);
    }
 
    if(isTouchScreenDevice())
    {
      document.addEventListener("touchmove", updateMousePosition);
    }
    else{
      document.addEventListener("mousemove", updateMousePosition);
    }
    window.addEventListener("resize", handleWindowResize);
  
    

   

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      if(isTouchScreenDevice())
    {
      document.removeEventListener("touchmove", updateMousePosition);
    }
    else{
      document.removeEventListener("mousemove", updateMousePosition);
    }
     
    };
  });

  let updateMousePosition = (e: any) => {
    var xm, ym;
    if( e.type == 'touchmove' ){
      var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
      var touch = evt.touches[0] || evt.changedTouches[0];
      xm = touch.pageX;
      ym = touch.pageY;
  } else if ( e.type == 'mousemove' ) {
      xm = e.clientX;
      ym = e.clientY;
  }
    let x = windowSize[0] / 2 -xm;
    let y = windowSize[1] / 2 -ym;
    if (!movement) {
      setRotationFactor([0, 0]);
    } else {
      setRotationFactor([x, y]);
    }
  };

  
  let lock3dMove = (move: boolean) => {
    setMovement(move);
    setRotationFactor([0, 0]);
  };

  const setters = [setP0Class, setP1Class, setP2Class];

  let changeScreen = (toScreen: number) => {
    if (toScreen != screen) {
    
      if(transition){
        setters[screen]("spe-turbulence-filter spe-fadeOut");
        transitionTurbulence(0.01, 0.3, () => {
          showScreen(toScreen);
        });
      }
      else{
        setters[screen]("spe-hidden");
        setScreen(toScreen);
        setters[toScreen]("");
      }
    }
  };
  let showScreen = (toScreen: number) => {
    setters[screen]("spe-hidden");
    setScreen(toScreen);
    setters[toScreen](" spe-fadeIn  spe-turbulence-filter");
    transitionTurbulence(0.3, 0.01, () => {
      setters[toScreen]("");
    });
  };

  const theme = createTheme({
    palette: {
      background: {
        paper: "#fff",
      },
      text: {
        primary: "#173A5E",
        secondary: "#456a74",
      },
      action: {
        active: "#001E3C",
      },

      primary: {
        main: "#3C8699",
      },
    },
  });

  let transitionTurbulence = (from: number, to: number, callback: Function) => {
    const frameRate = 20;
    const time = 400; //ms
    let current_frame = 0;
    const frames = (time / 1000) * frameRate;

    if (from < to) {
      current_frame = 1;
      const delta = (to - from) / frames;
      var my_timer = setInterval(function () {
        if (current_frame < frames) {
          setTurbulence("0 " + delta * current_frame);
        } else {
          clearInterval(my_timer);
          callback();
        }
        current_frame++;
      }, Math.floor(time / frames));
    } else {
      current_frame = frames;
      const delta = (from - to) / frames;
      var my_timer = setInterval(function () {
        if (current_frame > 0) {
          setTurbulence("0 " + delta * current_frame);
        } else {
          clearInterval(my_timer);
          callback();
        }
        current_frame--;
      }, Math.floor(time / frames));
    }
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };
  const [scrollTop, setScrollTop] = useState(0);


  const handleScroll = (event: any) => {
    setScrollTop(event.currentTarget.scrollTop);

    if (timer) {

      clearTimeout(timer);
    }

    let timerTemp = setTimeout((st) => {
      console.info(st);
      if (st <= section1!.current!.offsetTop) {
        setTab(0);
      } else if (st <= section2!.current!.offsetTop) {
        setTab(1);
      } else if (st <= section3!.current!.offsetTop) {
        setTab(2);
      } else if (st <= section4!.current!.offsetTop) {
        setTab(3);
      } else if (st <= section5!.current!.offsetTop) {
        setTab(4);
      } else if (st <= section6!.current!.offsetTop) {
        setTab(5);
      } else if (st <= section7!.current!.offsetTop) {
        setTab(6);
      }
    }, 300, event.currentTarget.scrollTop);
    setTimer(timerTemp)



  };
  let scrollToSection = (section: any) => {

    scroller.current?.scrollTo({ top: section.current.offsetTop - 60 });
    //section.current.scrollIntoView({ behavior: "smooth" });
  };



  const [language, setLanguage] = useState( i18n.language);
  let handleChangeLanguage =(
    event: React.MouseEvent<HTMLElement>,
    language: string,
  ) => {
    if(person){
      setSearchParams({["person"]: person,["lng"]: language}) 
    }
    else{
      setSearchParams({["lng"]: language}) 
    }
        
    //setLanguage(language)
    //i18n.changeLanguage(language);
  };
  


  
  return (
    <ThemeProvider theme={theme}>
    
      <Drawer anchor='bottom' open={drawerOpen} onClose={toggleDrawer(false)}>
        <List subheader={<ListSubheader>{t("Settings")}</ListSubheader>} >
        <Divider  component='li' />
        <ListItem>
        <ListItemIcon>
          <ControlCameraIcon/>
        </ListItemIcon>
        <ListItemText id="switch-list-three-d" primary={t("3D Rotation")} />
        <Switch
          edge="end"
          onChange={() => {
            lock3dMove(!movement);
          }}
          checked={movement}
          inputProps={{
            'aria-labelledby': 'switch-list-three-d',
          }}
        />
      </ListItem>
        <Divider  component='li' />
        <ListItem>
        <ListItemIcon>
          <OndemandVideoIcon/>
        </ListItemIcon>
        <ListItemText id="switch-list-three-d" primary={t("Video Background")} />
        <Switch
          edge="end"
          onChange={() => {
            setVideoBackground(!videoBackground) 
            
          }}
          checked={videoBackground}
          inputProps={{
            'aria-labelledby': 'switch-list-three-d',
          }}
        />
      </ListItem>
      <Divider  component='li' />
        <ListItem>
        <ListItemIcon>
          <AnimationIcon/>
        </ListItemIcon>
        <ListItemText id="switch-list-three-d" primary={t("Transitions")} />
        <Switch
          edge="end"
          onChange={() => {
            setTransition(!transition) 
          }}
          checked={transition}
          inputProps={{
            'aria-labelledby': 'switch-list-three-d',
          }}
        />
      </ListItem>
      <Divider  component='li' />
        <ListItem>
        <ListItemIcon>
          <TranslateIcon/>
        </ListItemIcon>
        <ListItemText id="switch-list-three-d" primary={t("Language")} />
        <ToggleButtonGroup
          color="primary"
          value={language}
          exclusive
          onChange={handleChangeLanguage}
          aria-label="Language"
        >
          <ToggleButton value="en">{t("English")}</ToggleButton>
          <ToggleButton value="fr">{t("French")}</ToggleButton>
          <ToggleButton value="pt">{t("PortugueseLang")}</ToggleButton>
        </ToggleButtonGroup>
      </ListItem>
      </List>

      
      </Drawer> 
      <div>
      { screen !== 2 && (
        <div className='spe-menu'>
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              if (newValue !== "2") {
                setValue(newValue);
                changeScreen(newValue);
              }
            }}
          >

            <BottomNavigationAction label={t("Curriculum Vitae")} value='1' icon={<PersonIcon />} />
            <BottomNavigationAction label={t("Settings")} value='2' icon={<DisplaySettingsIcon />} onClick={toggleDrawer(true)} />
          </BottomNavigation>
        </div>
        )}
        <div className={'spe-bg-video' + (videoBackground?'':' spe-hidden') + (screen===2?' spe-blured':'' )}>
          <video autoPlay={true} muted loop style={{ left: -9999 + rotationFactor[0] /50, top: -9999 + rotationFactor[1]/50  }}>
            <source src={video} type='video/mp4'></source>
          </video>
        </div>
        <div className={"spe-main " + p1Class}>
          <Container fixed>
           
            <div className='sp-screen' style={{ transform: " perspective(1000px) rotateY(" + rotationFactor[0] / 80 + "deg) rotateX(" + rotationFactor[1] / 80 + "deg) ", }} >
              <Grid container spacing={0}>
                <Grid item xs={12} md={4} p={{ xs: 0, sm: 0, md: 1 }}>
                  <div className='spe-photo-container'>
                    <div className='spe-photo '>
                      <div></div>
                    </div>
                  </div>
                  <Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
                    className='spe-contact spe-scrollbar'
                  >
                    {<Contact></Contact>}
                  </Box>
                </Grid>
                <Grid item xs={12} md={8} pt={{ xs: 0, sm: 0, md: 1 }} pb={{ xs: 0, sm: 0, md: 1 }} pr={{ xs: 0, sm: 0, md: 1 }} pl={0}>
                  <div className='spe-scrollable spe-scrollbar ' ref={scroller} onScroll={handleScroll} >
                    <div className='spe-scroll-content'>
                      <Box sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none", }, }} className='spe-contact spe-scrollbar' >
                        {<Contact></Contact>}
                      </Box>

                      <Tabs scrollButtons='auto'
                        sx={{zIndex:'4', boxShadow: '0 5px 5px #00000066', backgroundColor: "#fff", position: "sticky", top: "0px"}}

                        value={tab}
                        variant='scrollable'
                        onChange={(event, value: number) => {
                          scrollToSection(sections[value]);
                          setTab(value);
                        }}
                        aria-label='basic tabs example' >
                          
                        <Tooltip title={t("ABOUT ME")} arrow><Tab icon={<AccountBoxIcon />} wrapped aria-label='ABOUT ME' /></Tooltip>
                        <Tooltip title={t("WORK EXPERIENCE")} arrow><Tab icon={<WorkIcon />} wrapped aria-label=' WORK EXPERIENCE' /></Tooltip>
                        <Tooltip title={t("EDUCATION")} arrow><Tab icon={<SchoolIcon />} wrapped aria-label='EDUCATION AND TRAINING' /></Tooltip>
                        <Tooltip title={t("LANGUAGE SKILLS")} arrow><Tab icon={<TranslateIcon />} wrapped aria-label='LANGUAGE SKILLS' /></Tooltip>
                        <Tooltip title={t("DIGITAL SKILLS")} arrow><Tab icon={<ComputerIcon />} wrapped aria-label='DIGITAL SKILLS' /></Tooltip>
                        <Tooltip title={t("DRIVING LICENCE")} arrow><Tab icon={<DirectionsCarIcon />} wrapped aria-label='DRIVING LICENCE' /></Tooltip>
                        <Tooltip title={t("HOBBIES")} arrow><Tab icon={<HomeRepairServiceIcon />} wrapped aria-label='HOBBIES AND INTERESTS' /></Tooltip>
                      </Tabs>
                      <Box p={2}>
                        <h3 ref={section1} className="spe-text-title" style={{marginLeft:0}}>
                        {t("ABOUT ME")}
                        </h3>
                        <div className="spe-text-box"  >
                        {t("ABOUT ME TEXT")}
                         
                        </div>
                        <h3 ref={section2} className="spe-text-title">
                         
                          {t("WORK EXPERIENCE")}
                        </h3>
                        <div className="spe-text-box"  >

                          <div className="spe-timeline">
                            <div className="spe-timeline-point">

                            </div>
                            <div className="spe-timeline-text">
                              <Typography variant='subtitle1' sx={{ color: "text.secondary" }}> {t("01/07/2013 – CURRENT –  Portugal")} </Typography>

                              <Typography variant='h5'> {t("Web Designer / Developer")}  </Typography>
                              
                              {t("Stime DSI Groupement Les Mousquetaires")}
                              <Typography variant='body2' pt={1}>  {t("WORK1 DEF1")} </Typography>
                              <Typography variant='body2' pt={1}> {t("WORK1 DEF2")}  </Typography>
                              <Typography variant='body2' pt={1}>  {t("WORK1 DEF3")}   </Typography>
                              <Typography variant='body2' pt={1}> {t("WORK1 DEF4")}   </Typography>
                              <Typography variant='body2' pt={1}> {t("WORK1 DEF5")}   </Typography>

                            </div>
                          </div>

                          <div className="spe-timeline">
                            <div className="spe-timeline-point">

                            </div>
                            <div className="spe-timeline-text">
                              <Typography variant='subtitle1' sx={{ color: "text.secondary" }}> </Typography>

                              <Typography variant='h5'> {t("Programador web Bee Cloud")}  </Typography>
                              
                              {t("Grupo Os mosqueteiros")} 
                              <Typography variant='body2' pt={1}>
                              {t("WORK2 DEF1")} 
                             
                              </Typography>
                              <Typography variant='body2' pt={1}> {t("WORK2 DEF2")} </Typography>
                              <Typography variant='body2' pt={1}>   {t("WORK2 DEF3")}  </Typography>
                              <Typography variant='body2' pt={1}>   {t("WORK2 DEF4")}  </Typography>


                            </div>
                          </div>

                        </div>
                        <h3 ref={section3} className="spe-text-title">
                          
                          {t("EDUCATION")} 
                        </h3>
                        <div className="spe-text-box"  >

                          <div className="spe-timeline">
                            <div className="spe-timeline-point">

                            </div>
                            <div className="spe-timeline-text">
                              <Typography variant='subtitle1' sx={{ color: "text.secondary" }}> {t("01/09/2004 – 31/07/2008 – Castelo Branco, Portugal")}  </Typography>

                              <Typography variant='h5'> {t("Licenciatura em Design de Comunicação e Audiovisual")} </Typography>
                             
                              <Typography variant='body2' py={1}> Campus da Talagueira , Avenida do Empresário , Castelo Branco, Portugal  </Typography>
                              
                              <Link  href="https://www.ipcb.pt/esart/escola-superior-de-artes-aplicadas" underline="none">
                              www.ipcb.pt/esart <ArrowOutwardIcon fontSize="small" />
                          </Link>
                                
                            </div>
                          </div>

                        </div>
                        <h3 ref={section4} className="spe-text-title">
                        {t("LANGUAGE SKILLS")} 
                        
                        </h3>
                        <div className="spe-text-box"  >
                          <Typography variant='subtitle1' sx={{ color: "text.secondary" }}> {t("Mother Tongue:")}  </Typography>
                          <Typography variant='h6' >{t("PortugueseLang")}</Typography> 
                          <Divider sx={{my:1}} />

                          <Typography variant='subtitle1'   sx={{ color: "text.secondary" }}>{t("Other Language:")} </Typography>
                          <Typography variant='h6'>{t("French")}</Typography> 

                          <div className="spe-language" >
                             <div >
                             <span>{t("Listening")}</span>
                             
                              <div className="spe-gauge spe-c2"> <div></div> <div></div> <div></div> <div></div><div></div> <div></div></div>
                             </div>
                             <div >
                             <span>{t("Reading")}</span>
                             <div className="spe-gauge spe-c2"> <div></div> <div></div> <div></div> <div></div><div></div> <div></div></div>
                             </div>
                             <div >
                             <span>{t("Spoken")}<br/> {t("production")}</span>
                             <div className="spe-gauge spe-c2"> <div></div> <div></div> <div></div> <div></div><div></div> <div></div></div>
                             </div>
                             <div  >
                             <span>{t("Spoken")}<br/> {t("interaction")}</span>
                             <div className="spe-gauge spe-c2"> <div></div> <div></div> <div></div> <div></div><div></div> <div></div></div>
                             </div>
                             <div  >
                             <span>{t("Writing")}</span>
                             <div className="spe-gauge spe-c1"> <div></div> <div></div> <div></div> <div></div><div></div> <div></div></div>
                             </div>
                          </div>
                        
                          <Divider sx={{my:1}} />
                          <Typography variant='h6'>English</Typography> 
                          <div className="spe-language" >
                             <div >
                              <span>{t("Listening")}</span>
                             
                              <div className="spe-gauge spe-c1"> <div></div> <div></div> <div></div> <div></div><div></div> <div></div></div>
                             </div>
                             <div >
                             <span>{t("Reading")}</span>
                             <div className="spe-gauge spe-b1"> <div></div> <div></div> <div></div> <div></div><div></div> <div></div></div>
                             </div>
                             <div >
                             <span>{t("Spoken")}<br/> {t("production")}</span>
                             
                             <div className="spe-gauge spe-b1"> <div></div> <div></div> <div></div> <div></div><div></div> <div></div></div>
                             </div>
                             <div  >
                             <span>{t("Spoken")}<br/> {t("interaction")}</span>
                             
                             <div className="spe-gauge spe-b1"> <div></div> <div></div> <div></div> <div></div><div></div> <div></div></div>
                             </div>
                             <div  >
                             <span>{t("Writing")}</span>
                             
                             <div className="spe-gauge spe-b1"> <div></div> <div></div> <div></div> <div></div><div></div> <div></div></div>
                             </div>
                          </div>

                        </div>
                        <h3 ref={section5} className="spe-text-title">
                        {t("DIGITAL SKILLS")}
                       
                        </h3>
                        <div className="spe-text-box"  >
                          <Typography variant='subtitle1'  mb={1} sx={{ color: "text.secondary" }}>{t("Programing skills")} </Typography>
                         
                          <Box sx={{display:"flex",gap: '10px',flexWrap:'wrap'}}>
                          <Chip label="Angular" sx={{flex:'1'}}  variant="outlined" />
                          <Chip label="Angularjs" sx={{flex:'1'}}  variant="outlined" />
                          <Chip label="JQuery" sx={{flex:'1'}} variant="outlined" />
                          <Chip label="Bootstrap" sx={{flex:'1'}} variant="outlined" />
                          <Chip label="JSON" sx={{flex:'1'}}  variant="outlined" />
                          <Chip label="HTML5" sx={{flex:'1'}}  variant="outlined" />
                          <Chip label="CSS" sx={{flex:'1'}}  variant="outlined" />
                          <Chip label="SQL" sx={{flex:'1'}} variant="outlined" />
                          <Chip label="Java" sx={{flex:'1'}}  variant="outlined" />
                          <Chip label="React JS"sx={{flex:'1'}} variant="outlined" />
                          <Chip label="Git" sx={{flex:'1'}} variant="outlined" />
                          </Box>
                          <Divider sx={{my:3}} />
                          <Typography variant='subtitle1'  mb={1}  sx={{ color: "text.secondary" }}> {t("Graphic skills")}</Typography>
                          

                          <Box sx={{display:"flex",gap: '10px',flexWrap:'wrap'}}  mb={1}>
                          <Chip label="Vectorização" sx={{flex:'1'}}   variant="outlined" />
                          <Chip label="Video Editing" sx={{flex:'1'}}   variant="outlined" />
                          <Chip label="Adobe Collection" sx={{flex:'1'}}   variant="outlined" />
                          <Chip label="Conhecimento básico de Modelização 3D"   variant="outlined" />
                          <Chip label=" Microsoft Office" sx={{flex:'1'}}  variant="outlined" />
                         
                          </Box>

                          
                        
                        </div>
                      
                        <h3 ref={section6} className="spe-text-title">
                       
                        {t("DRIVING LICENCE")}
                        </h3>
                        <div className="spe-text-box"  >
                          <Typography variant='subtitle1'   sx={{ color: "text.secondary" }}> {t("Driving Licence:")} <span style={{color:'#000'}}> B</span> </Typography>
                        </div>
                       
                        <h3 ref={section7} className="spe-text-title">
                        {t("HOBBIES")}
                        </h3>
                        <div className="spe-text-box"  >
                          <Typography variant='subtitle1'   sx={{ color: "text.secondary" }}> {t("Lego")}</Typography>
                          {t("HOBBY PART1")}<br/> <br/>  {t("HOBBY PART2")} <br/> <br/> 
                        <Link href="https://www.flickr.com/photos/tkel86" underline="none">
                        {t("Flickr page")}<ArrowOutwardIcon fontSize="small" />
                          </Link>
                        </div>
                       
                       
                       
                       
                      </Box>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        
        <div className={"spe-main " + p2Class}>
          <Container fixed>
          
            
            <div className='sp-screen-welcome' style={{  transform: " perspective(1000px) rotateY(" + rotationFactor[0] / 80 + "deg) rotateX(" + rotationFactor[1] / 80 + "deg) ", }}>
              <div >
                  <div className='spe-photo-welcome-container'>
                    <div className='spe-photo '>
                      <div></div>
                    </div>
                  </div>
                
              <Typography variant='h4' mt={0}  mb={1}  sx={{ color: "text.secondary" }}>{t("Alexis")} {t("Dos Santos")} </Typography>
              <Typography variant='subtitle1'  mb={1}  > {t("Curriculum Vitae")} </Typography>
              
              <Typography variant='h5'  mb={2}  >
              {
                person?t("Welcome") + person + t("Thanks"):("Welcome") + t("Thanks")
              }
              </Typography>
             
              <Button variant="contained" endIcon={<ArrowForwardIosIcon />} onClick={()=>{changeScreen(1)}}>
              
              {t("Continue")} 
              </Button>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <svg xmlns='http://www.w3.org/2000/svg' version='1.1' className='svg-filters'>
        <defs>
          <filter id='turbulence'>
            <feTurbulence type='fractalNoise' baseFrequency={turbulence} numOctaves='1' result='warp'></feTurbulence>
            <feDisplacementMap xChannelSelector='R' yChannelSelector='G' scale='30' in='SourceGraphic' in2='warpOffset' />
          </filter>
        </defs>
      </svg>
    </ThemeProvider>
  );
}
