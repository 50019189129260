import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "about me line 1":"I´m a  Web Designer and AFOL.",
      "about me line 2":"So in my free time i try to combine these two passions to create tools that i use and now i have the pleasure of sharing them with everyone.",
      "about me line 3":" As an afol I like to build almost anything but my favorite challenge is anything new that envolve movement.",
      "Name":"Name",
      "Birth Year":"Birth Year",
      "Occupation":"Occupation",
      "Developper/Web Designer":"Developper/Web Designer",
      "Hobbies":"Hobbies",
      "Lego User Group":"Lego User Group",
      "Photography, Painting, poterry, Diy of all kinds (electronic, carpentry ...)":"Photography, Painting, poterry, Diy of all kinds (electronic, carpentry ...)",
      "Contact":"Contact",
      "Footer support 1":"Want to support this site,",
      "Footer support 2":"buy one of my Lego instructions on",
      "Interesting and inspiring":"Interesting and inspiring",
      "Menu":"Menu",
      "Lego Letters":"Lego Letters",
      "Lego Tools":"Lego Tools",
      "Hypotenuse":"Hypotenuse",
      "Angle Connections":"Angle Connections",
      "Gears":"Gears",
      "About Me":"About Me",
      "Support Me":"Support Me",
      "Find possible liftarms connections":"Find possible liftarms connections",
      "Connections between 2 liftarms":"Connections between 2 liftarms",
      "Combine multiples gears":"Combine multiples gears",
      "Combine multiples gears to get the ratio and speed":"Combine multiples gears to get the ratio and speed",
      "Find possible lego liftarms connections to a stud grid":"Find possible lego liftarms connections to a stud grid",
      "Find more about me":"Find more about me",
      "Support this project":"Support this project",
      "Instagram":"Instagram",
      "Flickr":"Flickr",
      "Lego Ideas":"Lego Ideas",
      "Check it out":"Check it out",
      "Open":"Open",
      "Let's go":"Let's go",
      "Recreates your name or any word with bricks,":"Recreates your name or any word with bricks,",
      "in an elegant and studless way.":"in an elegant and studless way.",
      "Home line 1":"Just a place to share some ideas, tools, stuff.",
      "Home line 2":" Hope you find something useful.",
      "Create Your own":"Create Your own",
      "Angle":"Angle",
      "Size":"Size",
      "Min-Max Angle":"Min-Max Angle",
      "Zoom":"Zoom",
      "Tolerance":"Tolerance",
      "Letters Configurations":"Letters Configurations",
      "Text":"Text",
      "Parts":"Parts",
      "Export XLSX":"Export XLSX",
      "XML Text":"XML Text",
      "XML Copied !":"XML Copied !",
      "Copy":"Copy",
      "Close":"Close",
      "Instructions for all letters  are available on Rebrickable":"Instructions for all letters  are available on Rebrickable",
      "Get Instructions on Rebrickable":"Get Instructions on Rebrickable",
      "Part List Generator":"Part List Generator",
      "You can combine any letter":"You can combine any letter",
      "Create Custom Letters, combining all the colors availables. And export the part list.":"Create Custom Letters, combining all the colors availables. And export the part list.",
      "Go Now":"Go Now",
      "Building Instructions":"Building Instructions",
      "Purshase the instructions for all letters on Rebrickable":"Purshase the instructions for all letters on Rebrickable",
      "Get it":"Get it",
      "support line 1":"If you like this initiative and would like to help keep this site active, you can do it in several ways,",
      "support line 2":"my social networks and",
      "support line 3":"them with others,",
      "support line 4":"a project of mine that you like on Lego Ideas or ",
      "support line 5":"one of my instructions for unique models in rebrickable. Anyway thanks for being here.",
      "following": "following",
      "sharing": " sharing ",
      "supporting": "supporting ",
      "buying": "buying ",
      "Rebrickable Instructions": "Rebrickable Instructions",
      "You can support me and this website just by buying one of my instruction on rebrickable.": "You can support me and this website just by buying one of my instruction on rebrickable.",
      "Buy an Instruction": "Buy an Instructioning",
      "You can also support one of my project on Lego Ideas.": "You can also support one of my project on Lego Ideas.",
      "Support a project": "Support a project",
      
      "Alexis": "Alexis", ///CV
      "Dos Santos":"Dos Santos",
      "Date of birth":"Date of birth",
      "Nationality": "Nationality",
      "Portuguese":"Portuguese",
      "Gender":"Gender",
      "Adress":" Portugal",
      "Email":"Email",
      "3D Rotation":"3D Rotation",
      "Video Background":"Video Background",
      "Transitions":"Transitions",
      "Download":"Download",
      "Curriculum Vitae":"Curriculum Vitae",
      "Settings":"Settings",
      "ABOUT ME":"ABOUT ME",
      "ABOUT ME TEXT":` I consider myself an excellent Front-end developer who
      over the years acquired skills and knowledge about a wide
      range of frameworks, eventually learning other skills
      related to Back-end. I also have graphic and audiovisual
      skills such as vectorization, video montage, creation of
      cooperative identities, photo editing.`,
      "WORK EXPERIENCE":"WORK EXPERIENCE",
      "01/07/2013 – CURRENT –  Portugal":"01/07/2013 – CURRENT –  Portugal",
      "Web Designer / Developer":"Web Designer / Developer",
      "Stime DSI Groupement Les Mousquetaires":"Stime DSI Groupement Les Mousquetaires",
      "WORK1 DEF1":`Definition and creation of all application design, logos,
      pictograms, page layout, specific components`,
      "WORK1 DEF2":`HTML,  Angular 2+ or React and css development`,
      "WORK1 DEF3":"Java development  occasionally",
      "WORK1 DEF4":"SQL development occasionally",
      "WORK1 DEF5":"Mongo development occasionally",
      "01/07/2007 – 30/06/2013 –  Portugal":"01/07/2007 – 30/06/2013 –  Portugal",
      "Programador web Bee Cloud":"Web Developer Bee Cloud",
      "Grupo Os mosqueteiros":"Grupo Os mosqueteiros",
      "WORK2 DEF1":`Definition and creation of all
      application design, logos, pictograms, page layout,
      specific components. Development of HTML, Javascript
      (Jquery) and css`,
      "WORK2 DEF2":"Java development occasionally ",
      "WORK2 DEF3":"SQL development occasionally",
      "WORK2 DEF4":"Project management occasionally",
      "EDUCATION":"EDUCATION",
      "01/09/2004 – 31/07/2008 – Castelo Branco, Portugal":"01/09/2004 – 31/07/2008 – Castelo Branco, Portugal",
      "Licenciatura em Design de Comunicação e Audiovisual":"Degree in Communication and Audiovisual Design",
      "University of Applied Arts IPCB":" University of Applied Arts - IPCB",
      "LANGUAGE SKILLS":"LANGUAGE SKILLS",
      "Mother Tongue:":"Mother Tongue:",
      "PortugueseLang":"Portuguese",
      "French":"French",
      "English":"English",
      "Listening":"Listening",
      "Reading":"Reading",
      "Spoken":"Spoken",
      "production":"production",
      "interaction":"interaction",
      "Writing":"Writing",
      "DIGITAL SKILLS":"DIGITAL SKILLS",
      "Programing skills":"Programing skills",
      "Graphic skills":"Graphic skills",
      "DRIVING LICENCE":"DRIVING LICENCE",
      "Driving Licence:":"Driving Licence:",
      "HOBBIES":"HOBBIES",
      "Lego":"Lego",
      "HOBBY PART1":`I participate and maintain for some years a hobby
      related to Lego. I have already been able to exhibit
      several of my creations at national as well as
      international events such as in France, Denmark, Spain and
      even Dubai.`,
      "HOBBY PART2":`I particularly like the challenge of combining
      functionality and aesthetics, creating something with
      complex mechanical movements while maintaining an
      appealing aesthetic.`,
      "Flickr page":"Flickr page",
      "Welcome":"Welcome ",
      "Thanks":" ,thanks for viewing my CV",
      "Continue":"Continue",
      "Language":"Language",
      
    }
  },
  fr: {
    translation: {
      "about me line 1":"Je suis Web Designer et AFOL.",
      "about me line 2":"Donc pendant mon temps libre j'essaie de combiner ces deux passions pour créer des outils que j'utilise et maintenant j'ai le plaisir de les partager avec vous.",
      "about me line 3":"En tant qu'afol, j'aime construire presque n'importe quoi, mais mon défi préféré est tout ce qui implique du mouvement.",
      "Name":"Nom",
      "Birth Year":"Année de naissance",
      "Occupation":"Profession",
      "Developper/Web Designer":"Developpeur/Web Designer",
      "Hobbies":"Loisirs",
      "Lego User Group":"Groupe d'utilisateurs Lego",
      "Photography, Painting, poterry, Diy of all kinds (electronic, carpentry ...)":"Photographie, Peinture, poterie, Bricolage en tout genre (électronique, menuiserie...)",
      "Contact":"Contact",
      "Footer support 1":"Vous souhaitez soutenir ce site,",
      "Footer support 2":"acheter une de mes instructions Lego sur",
      "Interesting and inspiring":"Intéressant et inspirant",
      "Menu":"Menu",
      "Lego Letters":"Lettres Lego",
      "Lego Tools":"Outils Lego",
      "Hypotenuse":"Hypoténuse",
      "Angle Connections":"Connexions d'angle",
      "Gears":"Engrenages",
      "About Me":"À propos de moi",
      "Support Me":"Me soutenir",
      "Find possible liftarms connections":"Trouver les connexions possibles de liftarms",
      "Connections between 2 liftarms":"Trouver les connexions possibles entre 2 liftarms",
      "Combine multiples gears":"Combinez plusieurs engrenages",
      "Combine multiples gears to get the ratio and speed":"Combinez plusieurs engrenages pour obtenir le rapport et la vitesse",
      "Find possible lego liftarms connections to a stud grid":"Trouvez les connexions possibles de liftarms à une grille de stud",
      "Find more about me":"En savoir plus sur moi",
      "Support this project":"Soutenez ce projet",
      "Instagram":"Instagram",
      "Flickr":"Flickr",
      "Lego Ideas":"Lego Ideas",
      "Check it out":"Voir plus",
      "Open":"Ouvrir",
      "Let's go":"Allons-y",
      "Recreates your name or any word with bricks,":"Recrée votre nom ou n'importe quel mot avec des briques,",
      "in an elegant and studless way.":"d'une manière élégante et sans stud.",
      "Home line 1":"Juste un endroit pour partager des idées, des outils, des trucs.",
      "Home line 2":" J'espère que vous trouverez quelque chose d'utile.",
      "Create Your own":"Crée le votre",
      "Angle":"Angle",
      "Size":"Taille",
      "Min-Max Angle":"Min-Max Angle",
      "Zoom":"Zoom",
      "Tolerance":"Tolérance",
      "Letters Configurations":"Configurations des lettres",
      "Text":"Texte",
      "Parts":"Pièces",
      "Export XLSX":"Exporter XLSX",
      "XML Text":"XML Texte",
      "XML Copied !":"XML Copié !",
      "Copy":"Copié",
      "Close":"Fermer",
      "Instructions for all letters  are available on Rebrickable":"Les instructions pour toutes les lettres sont disponibles sur Rebrickable",
      "Get Instructions on Rebrickable":"Obtenir les instructions sur Rebrickable",
      "Part List Generator":"Générateur de liste de pièces",
      "You can combine any letter":"Vous pouvez combiner n'importe quelle lettre",
      "Create Custom Letters, combining all the colors availables. And export the part list.":"Créez des lettres personnalisées en combinant toutes les couleurs disponibles. Et exportez la liste des pièces.",
      "Go Now":"Essayer",
      "Building Instructions":"Instructions de construction",
      "Purshase the instructions for all letters on Rebrickable":"Achetez les instructions pour toutes les lettres sur Rebrickable",
      "Get it":"Adquérir",
      "support line 1":"Si vous aimez cette initiative et souhaitez aider à maintenir ce site actif, vous pouvez le faire de plusieurs manières,",
      "support line 2":"mes réseaux sociaux et",
      "support line 3":"avec d'autres,",
      "support line 4":" un de mes projets que vous aimez sur Lego Ideas ou",
      "support line 5":"une de mes notices de modèles sur rebrickable. Quoi qu'il en soit merci d'être là.",
      "following": "suivant",
      "sharing": " les partager ",
      "supporting": " soutenir ",
      "buying": " achetant ",
      "Rebrickable Instructions": "Instructions sur Rebrickable",
      "You can support me and this website just by buying one of my instruction on rebrickable.": "Vous pouvez me soutenir, ainsi que ce site Web, simplement en achetant une de mes instructions sur le rebrickable.",
      "Buy an Instruction": "Acheter une notice",
      "You can also support one of my project on Lego Ideas.": "Vous pouvez également soutenir un de mes projets sur Lego Ideas.",
      "Support a project": "Soutenir un projet",
      

      "Alexis": "Alexis",// cv
      "Dos Santos":"Dos Santos",
      "Date of birth":"Données de naissance",
      "Nationality": "Nationalité",
      "Portuguese":"Portugais",
      "Gender":"Genre",
      "Adress":" Portugal",
      "Email":"Email",
      "3D Rotation":"Rotation  3D",
      "Video Background":"Vidéo de fond",
      "Transitions":"Transitions",
      "Download":"Télécharger",
      "Curriculum Vitae":"Curriculum Vitae",
      "Settings":"Paramètres",
      "ABOUT ME":"À PROPOS DE MOI",
      "ABOUT ME TEXT":` Je me considère comme un excellent développeur front-end qui, au fil des 
      ans a acquis des compétences et des connaissances sur un large éventail de
      frameworks, ayant éventuellement appris d'autres compétences plus
      liés au back-end. J'ai aussi des compétence graphique et
       audiovisuelles telles que la vectorisation, le montage vidéo, la création de
      identités coopératives, retouche photo`,
      "WORK EXPERIENCE":"EXPÉRIENCE PROFESSIONNELLE",
      "01/07/2013 – CURRENT –  Portugal":"01/07/2013 – ACTUELLE –  Portugal",
      "Web Designer / Developer":"Web Designer / Developeur",
      "Stime DSI Groupement Les Mousquetaires":"Stime DSI Groupement Les Mousquetaires",
      "WORK1 DEF1":`Définition et création de tous les designs d'applications, logos,
      pictogrammes, mise en page, composants spécifiques.`,
      "WORK1 DEF2":`Développement de HTML, Angular 2+ ou React e css.`,
      "WORK1 DEF3":"Développement en Java occasionnellement.",
      "WORK1 DEF4":"Développement en SQL occasionnellement.",
      "WORK1 DEF5":"Développement en MONGO occasionnellement",
      "01/07/2007 – 30/06/2013 –  Portugal":"01/07/2007 – 30/06/2013 –  Portugal",
      "Programador web Bee Cloud":"Développeur web Bee Cloud",
      "Grupo Os mosqueteiros":"Grupo Os mosqueteiros",
      "WORK2 DEF1":`Définition et création de tous les designs d'applications, logos,
      pictogrammes, mise en page, composants spécifiques.`,
      "WORK2 DEF2":"Développement de HTML, Javascript (Jquery) e css.",
      "WORK2 DEF3":"Développement en Java occasionnellement.",
      "WORK2 DEF4":"Gestion de projet occasionnellement",
      "EDUCATION":"ÉDUCATION",
      "01/09/2004 – 31/07/2008 – Castelo Branco, Portugal":"01/09/2004 – 31/07/2008 – Castelo Branco, Portugal",
      "Licenciatura em Design de Comunicação e Audiovisual":"Licence en Communication et Design Audiovisuel",
      "University of Applied Arts IPCB":" Ecole Supérieure des Arts Appliqués - IPCB",
      "LANGUAGE SKILLS":"COMPÉTENCES LINGUISTIQUES",
      "Mother Tongue:":"Langue maternelle:",
      "PortugueseLang":"Portugais",
      "French":"Français",
      "English":"Anglais",
      "Listening":"Compréhension ",
      "Reading":"Lecture",
      "Spoken":"Oral",
      "production":"(Production)",
      "interaction":"(Interaction)",
      "Writing":"Ecriture",
      "DIGITAL SKILLS":"COMPÉTENCES NUMÉRIQUES",
      "Programing skills":"Compétences en programmation",
      "Graphic skills":"Compétences graphiques",
      "DRIVING LICENCE":"PERMIS DE CONDUIRE",
      "Driving Licence:":"Permis de conduire:",
      "HOBBIES":"LOISIRS",
      "Lego":"Lego",
      "HOBBY PART1":`Je participe et entretiens depuis quelques années maintenant un hobby lié aux constructions en Lego. j'ai pu déjà
      exposer plusieurs de mes créations dans des événements nationaux et internationaux comme en France, au
       Danemark, en Espagne et même au Dubaï. `,
      "HOBBY PART2":`J'aime particulièrement le défi de combiner fonctionnalité et esthétique, créer quelque chose avec des mouvements mécaniques complexes tout en conservant une esthétique attrayante.`,
      "Flickr page":"Flickr ",
      "Welcome":"Bienvenue ",
      "Thanks":" , merci d'avoir consulté mon CV",
      "Continue":"Continuer",
      "Language":"Langue",
    }},
  pt: {
    translation: {
      "about me line 1":"Sou um Web Designer e AFOL.",
      "about me line 2":"Sendo assim no tempo livre tempo conciliar estas duas paixões para criar ferramentas úteis para mim que agora partilho com vocês.",
      "about me line 3":"Enquanto AFOL gosto de construir praticamente de tudo, mas tenho um interesse especial por tudo o que tenha movimento.",
      "Name":"Nome",
      "Birth Year":"Ano de nascimento",
      "Occupation":"Profissão",
      "Developper/Web Designer":"Programador/Web Designer",
      "Hobbies":"Passatempos",
      "Lego User Group":"Groupe de Lego",
      "Photography, Painting, poterry, Diy of all kinds (electronic, carpentry ...)":"Fotografia, pintura, oleria, Fvm de imensos tipos (electrónica, carpintaria ...)",
      "Contact":"Contacto",
      "Footer support 1":"Se quiser ajudar este site,",
      "Footer support 2":"adquira uma das minhas instruções de Lego",
      "Interesting and inspiring":"Interessante e inspiradores",
      "Menu":"Menu",
      "Lego Letters":"Letras de Lego",
      "Lego Tools":"Ferramentas de Lego",
      "Hypotenuse":"Hypotenuse",
      "Angle Connections":"Conexões num ângulo",
      "Gears":"Engrenagens",
      "About Me":"Sobre Mim",
      "Support Me":"Apoiar",
      "Find possible liftarms connections":"Encontra possiveis ligações de liftarms",
      "Connections between 2 liftarms":"Conexões entre 2 liftarms",
      "Combine multiples gears":"Combina multiplas engrenagens",
      "Combine multiples gears to get the ratio and speed":"Combina multiplas engrenagens e descubra o ratio e a velocidade",
      "Find possible lego liftarms connections to a stud grid":"Encontra possiveis ligações de liftarms numa grelha de studs",
      "Find more about me":"Descubra mais sobre mim",
      "Support this project":"Apoiar este projecto",
      "Instagram":"Instagram",
      "Flickr":"Flickr",
      "Lego Ideas":"Lego Ideas",
      "Check it out":"Experimenta",
      "Open":"Abrir",
      "Let's go":"Vamos lá",
      "Recreates your name or any word with bricks,":"Recria o seu nome ou qualquer palavra em bricks,",
      "in an elegant and studless way.":"de uma forma elegante e studless.",
      "Home line 1":"Simplesmente um lugar para partilhar ideias, ferramentas, coisas.",
      "Home line 2":" Espero que encontra aqui algo útil",
      "Create Your own":"Cria o seu",
      "Angle":"Ângulo",
      "Size":"Tamanho",
      "Min-Max Angle":"Min-Max Ângulo",
      "Zoom":"Zoom",
      "Tolerance":"Tolerância",
      "Letters Configurations":"Configurações de Letras",
      "Text":"Texto",
      "Parts":"Peças",
      "Export XLSX":"Exportar XLSX",
      "XML Text":"XML Texto",
      "XML Copied !":"XML Copiado !",
      "Copy":"Copiar",
      "Close":"Fechar",
      "Instructions for all letters  are available on Rebrickable":"As instruções para todas as letras estão disponíveis no Rebrickable",
      "Get Instructions on Rebrickable":"Obter as instruções no Rebrickable",
      "Part List Generator":"Gerador de lista de peças",
      "You can combine any letter":"Você pode combinar qualquer letra",
      "Create Custom Letters, combining all the colors availables. And export the part list.":"Crie Letras Personalizadas, combinando todas as cores disponíveis. E exporte a lista de peças.",
      "Go Now":"Experimenta agora",
      "Building Instructions":"Instruções de construção",
      "Purshase the instructions for all letters on Rebrickable":"Adquira as instruções para todas as letras no Rebrickable",
      "Get it":"Adquerir",
      "support line 1":"Se gosta desta iniciativa e gostaria de ajudar a manter este site ativo, pode fazê-lo de várias formas,",
      "following": "seguindo ",
      "support line 2":"as minhas redes sociais e",
      "sharing": " partilhando-as ",
      "support line 3":"com outros, ",
      "supporting": "apoiando ",
      "support line 4":"um projeto meu que você goste no Lego Ideas ou",
      "buying": " comprando ",
      "support line 5":"uma das minhas instruções de criações minhas no rebrickable. De qualquer forma obrigado por estar aqui.",
      
      
      
     
      "Rebrickable Instructions": "Instruções no Rebrickable ",
      "You can support me and this website just by buying one of my instruction on rebrickable.": "Você pode apoiar-me a mim e a este site simplesmente comprando uma das minhas instruções no rebrickable.",
      "Buy an Instruction": "Adquira uma Instrução",
      "You can also support one of my project on Lego Ideas.": "Você também pode apoiar um dos meus projetos no Lego Ideas.",
      "Support a project": "Apoiar um projecto",
      

      "Alexis": "Alexis",//cv
      "Dos Santos":"Dos Santos",
      "Date of birth":"Data de nascimento",
      "Nationality": "Nacionalidade",
      "Portuguese":"Português",
      "Gender":"Gênero",
      "Adress":" Portugal",
      "Email":"Email",
      "3D Rotation":"Rotação 3D",
      "Video Background":"Video de fundo",
      "Transitions":"Transições",
      "Download":"Download",
      "Curriculum Vitae":"Curriculum Vitae",
      "Settings":"Configurações",
      "ABOUT ME":"SOBRE MIM",
      "ABOUT ME TEXT":` Considero-me um excelente desenvolvedor Front-end que ao longo dos
      anos adquiriu aptidões e conhecimentos sobre um vasto leque de
      frameworks, tendo eventualmente aprendido outras competências mais
      relacionadas com Back-end. Também possuo aptidões gráficas e de
      audiovisuais como vectorização, montagem de vídeos, criação de
      identidades cooperativas, edição fotográfica`,
      "WORK EXPERIENCE":"EXPERIÊNCIA PROFISSIONAL",
      "01/07/2013 – CURRENT –  Portugal":"01/07/2013 – ATUAL –  Portugal",
      "Web Designer / Developer":"Web Designer / Developer",
      "Stime DSI Groupement Les Mousquetaires":"Stime DSI Groupement Les Mousquetaires",
      "WORK1 DEF1":`Definição e creação de todo o design de aplicativos, logotipos ,
      pictogramas, layout de paginas, componentes especificos.`,
      "WORK1 DEF2":`Desenvolvimento de HTML, Angular 2+ ou React e css.`,
      "WORK1 DEF3":"Desenvolvimento em Java ocasionalmente.",
      "WORK1 DEF4":"Desenvolvimento em SQL ocasionalmente.",
      "WORK1 DEF5":"Desenvolvimento em MONGO ocasionalmente",
      "01/07/2007 – 30/06/2013 –  Portugal":"01/07/2007 – 30/06/2013 –  Portugal",
      "Programador web Bee Cloud":"Programador web Bee Cloud",
      "Grupo Os mosqueteiros":"Grupo Os mosqueteiros",
      "WORK2 DEF1":`Definição e creação de todo o design de aplicativos, logotipos ,
      pictogramas, layout de paginas, componentes especificos.`,
      "WORK2 DEF2":"Desenvolvimento de HTML, Javascript (Jquery) e css.",
      "WORK2 DEF3":"Desenvolvimento em Java ocasionalmente.",
      "WORK2 DEF4":"Gestão de projectos ocasionalmente",
      "EDUCATION":"EDUCAÇÃO",
      "01/09/2004 – 31/07/2008 – Castelo Branco, Portugal":"01/09/2004 – 31/07/2008 – Castelo Branco, Portugal",
      "Licenciatura em Design de Comunicação e Audiovisual":"Licenciatura em Design de Comunicação e Audiovisual",
      "University of Applied Arts IPCB":" Escola superior de Artes Aplicadas - IPCB",
      "LANGUAGE SKILLS":"COMPETÊNCIAS LINGUÍSTICAS",
      "Mother Tongue:":"Língua materna:",
      "PortugueseLang":"Português",
      "French":"Francês",
      "English":"Inglês",
      "Listening":"Compreensão ",
      "Reading":"Leitura",
      "Spoken":"Oral",
      "production":"(Produção)",
      "interaction":"(Interação)",
      "Writing":"Escrever",
      "DIGITAL SKILLS":"COMPETÊNCIAS DIGITAIS",
      "Programing skills":"Competências de programação",
      "Graphic skills":"Competências gráficas",
      "DRIVING LICENCE":"CARTA DE CONDUÇÃO",
      "Driving Licence:":"Carta de condução:",
      "HOBBIES":"PASSATEMPOS",
      "Lego":"Lego",
      "HOBBY PART1":`Participo e mantenho a já alguns anos um hobby relacionado com construções feitas em Lego. Já pude
      expor várias criações minhas em eventos nacionais bem como internacionais como em França, na
      Dinamarca, em Espanha e até no Dubai. `,
      "HOBBY PART2":`Gosto particularmente do desafio de conjugar funcionalidade e estética, criando algo com movimentos mecânicos complexos mantendo, no entanto, uma estética apelativa.`,
      "Flickr page":"Página Flickr ",
      "Welcome":"Bem-vindo ",
      "Thanks":" , obrigado por vizualizar o meu currículo",
      "Continue":"Continuar",
      "Language":"Linguagem",
    }
  }
};
const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: options,
    resources,
    supportedLngs: [ 'en', 'pt', 'fr'],
   
  });

  export default i18n;